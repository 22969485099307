define(function(require) {
    const ServiceMenuTemplate = require('./ServiceMenu.template.html');
    const BaseView = require('app/Base.view');
    const NotificationMenuView = require('pages/notifications/NotificationMenu.view');
    const ConfirmationModalView = require('./modals/ConfirmationModal.view');
    const CurrentClientSwitcherView = require('app/CurrentClientSwitcher.view');
    const ImpersonationMenuView = require('./ImpersonationMenu.view');
    const { authorize } = require('utils/authorization');

    const _ = require('underscore');
    const CC = require('CC');
    require('vendor/keymaster');

    const ServiceMenuView = BaseView.extend({
        template: _.template(ServiceMenuTemplate),

        defaults: function() {
            return {
                hasDataOverride: CC.utils.getUserInfo().get('hasDataOverride')
            };
        },

        initialize: function(options) {
            const self = this;
            _.extend(self, self.defaults(), options);

            self.listenTo(CC.Events, 'ShortcutKey:AccountSwitch', () => self.onAccountSwitchClick());
        },

        events: {
            'click #changeAccount': 'onAccountSwitchClick',
            'click #fireImpersonation': 'onClickImpersonate'
        },

        render: function() {
            const self = this;
            const viewOptions = {
                isLotameAdmin: CC.authorizeByPermission('Administration'),
                canAdminUsers: CC.authorizeByPermission('ManageUsers'),
                canManageSyncPixels:
                    CC.authorizeByPermission('ManageSyncPixels') && authorize('SyncPixelPrioritization'),
                showClientSelection:
                    CC.authorizeByPermission('Administration') || CC.SessionData.userOrgs.models.length > 1,
                canReorderGroupings: CC.authorizeByPermission('Manage Hierarchies'),
                app: self.app,
                links: [
                    {
                        name: 'Lotame',
                        id: 24,
                        title: 'Lotame'
                    }
                ],
                currentAccount: CC.utils.getCurrentClientName(),
                currentUsername: CC.utils.getUserInfo().get('username'),
                currentName: `${CC.utils.getUserInfo().get('firstName')} ${CC.utils.getUserInfo().get('lastName')}`,
                currentClientId: CC.utils.getCurrentClientId(),
                lotameAssistActive: CC.getConfigSetting('lotameAssistActive', false),
                helpUrl: CC.getConfigSetting('HelpUrl'),
                logoutUrl: `${CC.apiUrl}/users/logout`,
                isClientManagedUser: CC.utils.getUserInfo().get('isClientManaged'),
                canImpersonate:
                    (self.hasDataOverride && CC.authorizeByPermission('Admin:ImpersonateUsers')) ||
                    CC.authorizeByPermission('CrossClientImpersonateUsers')
            };
            // Set the default settings page depending on what we have access to.
            viewOptions.defaultSettingsPage = '';
            if (viewOptions.canAdminUsers) {
                viewOptions.defaultSettingsPage = '/users';
            } else if (viewOptions.canManageSyncPixels) {
                viewOptions.defaultSettingsPage = '/syncPixels';
            } else if (viewOptions.canReorderGroupings) {
                viewOptions.defaultSettingsPage = '/taxonomyGroupingsDisplayOrder';
            }

            self.$el.html(this.template(viewOptions));

            self.renderSubView('#notification-count', NotificationMenuView, {
                menuScheme: self.menuScheme
            });

            return self;
        },

        onAccountSwitchClick: function(e) {
            CC.Events.trigger(
                'Page:showModal',
                new ConfirmationModalView({
                    modalId: 'switchConfirm',
                    saveText: 'Yes',
                    modalTitle: 'Switch Client',
                    showClose: false,
                    showSave: false,
                    showCancel: true,
                    isScrollable: false,
                    contentView: new CurrentClientSwitcherView({})
                })
            );
        },

        onClickImpersonate: function() {
            CC.Events.trigger(
                'Page:showModal',
                new ConfirmationModalView({
                    modalId: 'impersonateModal',
                    modalTitle: 'Impersonation',
                    contentView: new ImpersonationMenuView(),
                    saveText: 'Start Impersonation',
                    savingText: 'Starting Impersonation...'
                })
            );

            CC.utils.trackEvent('GlobalNav', 'NavBar - Clicked', 'ImpersonationBegin');
        }
    });

    return ServiceMenuView;
});
