define(function(require) {
    const ErrorNavTemplate = require('./ErrorNav.template.html');
    const BaseView = require('app/Base.view');
    const $ = require('jquery');
    const _ = require('underscore');

    const leftNavMenu = BaseView.extend({
        template: _.template(ErrorNavTemplate),

        events: {},

        render: function() {
            const self = this;
            const $el = $(this.el);
            $el.html(self.template());
            return self;
        }
    });

    return leftNavMenu;
});
