// DateUtils - collection of date utilities.
// Done as an optional include as not every view will need it.
define(function(require) {
    const CC = require('CC');
    const _ = require('underscore');
    const moment = require('moment');
    const DateUtils = {
        // Default values for Intl.DateTimeFormat date formatting
        defaultIntlDateTimeFormatLocale: 'en-US',
        defaultIntlDateTimeFormatOptions: {
            hour12: true,
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short'
        },

        getIntlDateTimeFormatter: function(locale, formatOptions) {
            if (_.isUndefined(locale) || typeof locale !== 'string') {
                locale = DateUtils.defaultIntlDateTimeFormatLocale;
            }
            if (_.isUndefined(formatOptions) || typeof formatOptions !== 'object') {
                formatOptions = DateUtils.defaultIntlDateTimeFormatOptions;
            }
            return new Intl.DateTimeFormat(locale, formatOptions);
        },

        getFormattedDateFromDate: function(inDate, model, format, fallback) {
            let formattedDate;
            if (_.isUndefined(format) || typeof format !== 'string') {
                format = CC.configData.default_date_format_moment;
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || !(inDate instanceof Date)) {
                formattedDate = fallback;
            } else {
                formattedDate = moment(inDate).format(format);
            }
            return formattedDate;
        },

        // Expected input data format: "2022-06-07T10:35:18Z"
        // created with Intl.DateTimeFormat object
        // TODO: rename this, it's not a "Date" inbound, but an ISO DateTime
        getFormattedDateTimeFromDate: function(inDate, model, dateTimeFormatter, fallback) {
            let formattedDate;
            if (_.isUndefined(dateTimeFormatter) || !(dateTimeFormatter instanceof Intl.DateTimeFormat)) {
                dateTimeFormatter = DateUtils.getIntlDateTimeFormatter();
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || _.isNull(inDate)) {
                formattedDate = fallback;
            } else {
                if (!(inDate instanceof Date)) {
                    inDate = new Date(inDate);
                }
                formattedDate = dateTimeFormatter.format(inDate);
            }
            return formattedDate;
        },

        // Expected input data format: "2022-06-07T10:35:18Z"
        // created with Intl.DateTimeFormat object (ISO DateTime)
        getFormattedDateFromISODateTime: function(inDate, model, dateTimeFormatter, fallback) {
            let formattedDate;
            if (_.isUndefined(dateTimeFormatter) || !(dateTimeFormatter instanceof Intl.DateTimeFormat)) {
                const formatterOptions = {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                };
                dateTimeFormatter = DateUtils.getIntlDateTimeFormatter(undefined, formatterOptions);
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || _.isNull(inDate)) {
                formattedDate = fallback;
            } else {
                if (!(inDate instanceof Date)) {
                    inDate = new Date(inDate);
                }
                formattedDate = dateTimeFormatter.format(inDate);
            }
            return formattedDate;
        },

        // Expected input date format: "07/26/2016 13:23 +0000" or "07/26/2016"
        // model parameter exists in order to be compatible with Backgrid formatter fromRaw()
        // calls moving forward. The model is not currently used.
        getFormattedDateFromDateTime: function(inDate, model, format, fallback) {
            let formattedDate;
            if (_.isUndefined(format) || typeof format !== 'string') {
                format = CC.configData.default_date_format_moment;
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || typeof inDate !== 'string') {
                formattedDate = fallback;
            } else {
                formattedDate = moment(inDate.substring(0, 10), 'MM/DD/YYYY').format(format);
            }
            return formattedDate;
        },

        //Converts 01/18/2017 into API friendly 20170118
        getAPIDateFromDateTime: function(inDate) {
            return moment(inDate, 'MM/DD/YYYY').format('YYYYMMDD');
        },

        // Expected input date format: "07/26/2016 13:23 +0000"
        // model parameter exists in order to be compatible with Backgrid formatter fromRaw()
        // calls moving forward. The model is not currently used.
        getFormattedDateTimeFromDateTime: function(inDate, model, format, fallback) {
            let formattedDate;
            if (_.isUndefined(format) || typeof format !== 'string') {
                format = CC.configData.default_date_format_moment;
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || typeof inDate !== 'string') {
                formattedDate = fallback;
            } else {
                formattedDate = moment(inDate, 'MM/DD/YYYY HH:mm Z').format(format);
            }
            return formattedDate;
        },

        // Expected input date format: YYYYMMDD
        // model parameter exists in order to be compatible with Backgrid formatter fromRaw()
        // calls moving forward. The model is not currently used.
        getFormattedDateFromDataDate: function(inDate, model, format, fallback) {
            let formattedDate;
            if (_.isUndefined(format) || typeof format !== 'string') {
                format = CC.configData.default_date_format_moment;
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || inDate === null) {
                formattedDate = fallback;
            } else {
                formattedDate = DateUtils.getDateFromDataDate(inDate).format(format);
            }
            return formattedDate;
        },

        /**
         *
         * @param {String} inDate in the form of YYYYMMDD
         * @returns A date object
         */
        getDateFromDataDate: function(inDate) {
            return moment(inDate, 'YYYYMMDD');
        },

        // Expected input date format: YYYYMM
        // model parameter exists in order to be compatible with Backgrid formatter fromRaw()
        // calls moving forward. The model is not currently used.
        getFormattedMonthFromDataDate: function(inDate, model, format, fallback) {
            let formattedDate;
            if (_.isUndefined(format) || typeof format !== 'string') {
                format = CC.configData.default_month_format_moment;
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || inDate === null) {
                formattedDate = fallback;
            } else {
                formattedDate = DateUtils.getMonthFromDataDate(inDate).format(format);
            }
            return formattedDate;
        },

        /**
         *
         * @param {String} inDate in the form of YYYYMM
         * @returns A date object
         */
        getMonthFromDataDate: function(inDate) {
            return moment(inDate, 'YYYYMM');
        },

        /**
        * @param {String} inDate in the form ofYYYY-MM-DD HH:MM:S
        * @returns A date object
        */
        getFormattedDateFromISO8601Date: function(inDate, model, format, fallback) {
            let formattedDate;
            if (_.isUndefined(format) || typeof format !== 'string') {
                format = CC.configData.default_date_format_moment;
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || inDate === null) {
                formattedDate = fallback;
            } else {
                formattedDate = moment(inDate, 'YYYY-MM-DD HH:mm:ss').format(format);
            }
            return formattedDate;
        },

        // Expected input date format: Unix Timestamp, e.g. 1219933574000
        // model parameter exists in order to be compatible with Backgrid formatter fromRaw()
        // calls moving forward. The model is not currently used.
        getFormattedDateFromTimestamp: function(inDate, model, format, fallback) {
            let formattedDate;
            if (_.isUndefined(format) || typeof format !== 'string') {
                format = CC.configData.default_date_format_moment;
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }
            if (_.isUndefined(inDate) || inDate === null) {
                formattedDate = fallback;
            } else {
                const timestamp = parseInt(inDate, 10);
                if (isNaN(timestamp) || timestamp <= 0) {
                    formattedDate = fallback;
                } else {
                    formattedDate = moment(parseInt(inDate, 10)).format(format);
                }
            }
            return formattedDate;
        },

        // When you don't know exactly which format to use. Not as performant as the specific
        // versions, so don't use this if you're operating on many items.
        getFormattedDate: function(inDate, model, format, fallback) {
            const self = this;
            let formattedDate;
            let isError = false;
            if (_.isUndefined(format) || typeof format !== 'string') {
                format = CC.configData.default_date_format_moment;
            }
            if (_.isUndefined(fallback) || typeof fallback !== 'string') {
                fallback = '';
            }

            if (_.isUndefined(inDate) || inDate === null) {
                formattedDate = fallback;
            } else if (moment.isMoment(inDate)) {
                // Moment.js object
                formattedDate = inDate.format(CC.configData.default_date_format_moment);
            } else if (inDate instanceof Date) {
                // JavaScript Date object
                formattedDate = self.getFormattedDateFromDate(inDate, model, format, fallback);
            } else if (_.isString(inDate)) {
                // string
                if (inDate.trim() === '') {
                    formattedDate = fallback;
                } else if (inDate.match(/^\d+$/)) {
                    if (inDate.length === 8) {
                        // YYYYMMDD format
                        formattedDate = self.getFormattedDateFromDataDate(inDate, model, format, fallback);
                    } else {
                        // timestamp as a string
                        formattedDate = self.getFormattedDateFromTimestamp(inDate, model, format, fallback);
                    }
                } else if (inDate.length >= 10 && inDate.substring(0, 10).match(/^\d{2}\/\d{2}\/\d{4}$/)) {
                    // "MM/DD/YYYY..." format
                    formattedDate = self.getFormattedDateFromDateTime(inDate, model, format, fallback);
                } else if (inDate.match(/^[A-Za-z]{3} \d{1}\, \d{4}$/)) {
                    // Already in form, e.g. 'Aug 1, 2016'. Assuming that's what we want, regurgitate
                    // !!! We must update this logic if our desired form changes
                    formattedDate = inDate;
                } else {
                    isError = true;
                }
            } else if (_.isNumber(inDate)) {
                // treat it like an integer
                if (inDate < 99999999) {
                    // int in YYYYMMDD format
                    formattedDate = self.getFormattedDateFromDataDate(inDate, model, format, fallback);
                } else {
                    // assuming a Unix timestamp
                    formattedDate = self.getFormattedDateFromTimestamp(inDate, model, format, fallback);
                }
            } else {
                isError = true;
            }

            if (isError) {
                console.error(`ERROR: call to getFormattedDate with unsupported format: ${JSON.stringify(inDate)}`);
                formattedDate = fallback;
            }

            return formattedDate;
        },

        getTimeSince: function(pastDate) {
            return moment(pastDate, 'MM/DD/YYYY HH:mm Z').fromNow();
        },

        isInBetweenDates: function(inDate, lowerBound, upperBound) {
            return moment(inDate).isAfter(lowerBound) && moment(inDate).isBefore(upperBound);
        }
    };

    return DateUtils;
});
