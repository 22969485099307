define(function(require) {
    const _ = require('underscore');
    const CC = require('CC');
    const DateUtils = require('DateUtils');
    const BaseView = require('app/Base.view');
    const notificationCountModel = require('models/notifications/notificationCount.model');
    const NotificationCollection = require('models/notifications/notification.collection');
    const NotificationMenuTemplate = require('./NotificationMenu.template.html');
    const NotificationContentTemplate = _.template(/* template */ `
         <% notificationCollection.each(function(notification){%>
            <a
                href="user/notifications?id=<%- notification.get('id') %>"
                class="notification-menu__summary <%- notification.get('actionDate') ? '-read' : '-unread' %>"
            >
                <div class="notification-menu__subject truncate-text"><%= notification.get('subject') %></div>
                <div class="notification-menu__timestamp"><%= DateUtils.getFormattedDateFromDate(notification.get('created'), notification, 'MMM Do, YYYY') %></div>
            </a>
        <% }); %>
    `);

    require('./notificationCount.css');

    const NotificationDropdownView = BaseView.extend({
        template: _.template(NotificationMenuTemplate),

        defaults: {
            notificationCount: new notificationCountModel(),
            notificationCollection: new NotificationCollection(null, {
                state: {
                    pageSize: 5
                }
            })
        },

        events: {
            'click #user-notification-count-container': 'onClick'
        },

        initialize: function(options) {
            _.extend(this, this.defaults, options);

            this.listenTo(CC.Events, 'NotificationCount:update', this.render);
        },

        render: function() {
            CC.utils.waitForXhrs([this.notificationCount.fetch()], () => {
                const viewOptions = {
                    count: this.notificationCount.get('count') > 99 ? '99+' : this.notificationCount.get('count')
                };

                this.$el.html(this.template(viewOptions));
            });
        },

        onClick: function() {
            this.renderContent();
        },

        renderContent: function() {
            this.$('.notification-menu__content').html(this.defaultLoader);

            this.notificationCollection.fetch({
                success: () => {
                    const viewOptions = {
                        notificationCollection: this.notificationCollection,
                        DateUtils: DateUtils
                    };

                    this.$('.notification-menu__content').html(NotificationContentTemplate(viewOptions));
                },
                error: (jqXHR /* , textStatus, errorThrown */) => {
                    const reason = CC.utils.getAjaxErrorMessage(
                        jqXHR,
                        'Failed to retrieve notifications. Try again in a few seconds'
                    );
                    this.renderAlert({
                        el: '.notification-menu__content',
                        message: reason
                    });
                }
            });
        }
    });

    return NotificationDropdownView;
});
