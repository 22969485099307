const CC = require('CC');
const UserSettingUtil = require('utils/userSetting');

export const authorizeForProfileCountStats = () => {
    return authorize('ProfileStats', {
        alphaUserSetting: 'showTotalProfilesAudienceStats'
    });
};

export const authorizationForGeneralDataPanel = function(params = {}) {
    return authorize('GeneralDataPanel');
};

export const authorizeForExplorationReport = () => {
    return authorize('ExplorationReport');
};

const hasBeenOverridenOn = name => CC.DataStore.get('with_feature') === name;

const hasBeenOverriddenOff = name => CC.DataStore.get('without_feature') === name;

/**
 * Authorize access by feature
 *
 * By default, checks agains the features enabled for the Current Client Id
 * If a client id is provided, use the features set for that client Id (aka what has been
 * @param {String} featureName
 * @param {Object} options
 * @param {Number?} options.clientId
 * @param {String} options.alphaUserSetting
 *
 * @returns {Boolean}
 */
export const authorize = (
    featureName,
    options = {
        clientId: undefined,
        alphaUserSetting: 'useUpdatedUIStyle'
    }
) => {
    // handling for case where no one is logged in. May remove and streamline that path
    if (typeof CC.SessionData.userInfo === 'undefined') {
        return false;
    }
    if (typeof CC.SessionData.allFeatures === 'undefined') {
        return false;
    }
    if (typeof CC.SessionData.userFeatures === 'undefined') {
        return false;
    }

    let isAllowed = false;
    const feature = CC.SessionData.allFeatures.findWhere({ name: featureName });
    if (feature) {
        switch (feature.get('rolloutStatus')) {
            case 'PREVIEW': {
                isAllowed = hasBeenOverridenOn(featureName);
                break;
            }
            case 'ALPHA':
                // Check user setting to see if they've opted into new features
                isAllowed = UserSettingUtil.getUserSetting(options.alphaUserSetting);
                break;
            case 'BETA':
            case 'GA': {
                if (options.clientId) {
                    const entityFeatures = CC.DataStore.get(`clientFeatures_${options.clientId}`);
                    if (entityFeatures) {
                        const clientFeature = entityFeatures.findWhere({ name: featureName });
                        isAllowed = !!clientFeature;
                        break;
                    }
                }
                // Check enabled feature using the current client id
                const clientFeature = CC.SessionData.userFeatures.findWhere({ name: featureName });
                isAllowed = !!clientFeature;
                break;
            }
            case 'INTEGRATED':
                // This feature flag check is ready to be removed from the code
                isAllowed = true;
                break;
            default:
                isAllowed = false;
        }

        //
        // Override
        //
        if (CC.utils.getUserSessionInfo('hasDataOverride')) {
            if (hasBeenOverridenOn(featureName)) {
                isAllowed = true;
            }

            if (hasBeenOverriddenOff(featureName)) {
                isAllowed = false;
            }
        }
    }

    return isAllowed;
};
