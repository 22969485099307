define(function(require) {
    const navMenuFeatureTemplate = require('./NavMenuFeature.template.html');
    const $ = require('jquery');
    const _ = require('underscore');
    const Backbone = require('backbone');

    const feature_view = Backbone.View.extend({
        template: _.template(navMenuFeatureTemplate),

        initialize: function(options) {
            this.feature = options.model || {};
        },

        render: function() {
            const self = this;
            const $el = $(this.el);

            $el.html(
                self.template({
                    feature: self.feature
                })
            );
            return this;
        }
    });
    return feature_view;
});
