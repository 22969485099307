define(function(require) {
    const _ = require('underscore');
    const CC = require('CC');
    const BaseCollection = require('models/Base.collection');
    const UserNotificationModel = require('models/notifications/notification.model');
    const notificationCollection = BaseCollection.extend({
        state: {
            sortKey: 'created',
            order: 1,
            totalRecords: 100
        },

        model: UserNotificationModel,

        options: {
            searchQueryParam: 'searchTerm'
        },

        urlRoot: function() {
            return `${CC.apiUrl}/notifications`;
        },

        parseRecordsAttribute: 'Notification',

        parseState: function(response) {
            const state = {
                totalRecords: parseInt(response.setInfo.numAvailable, 10)
            };
            return state;
        },

        // Parse the created and action date
        parseRecords: function(response) {
            const records = BaseCollection.prototype.parseRecords.call(this, response);
            _.each(records, record => {
                const createdDateStr = record.created;
                record.created = new Date(createdDateStr);
                record.context = record.context.replace('PDX', 'Collaborate'); // old notifications may still have the PDX title
            });
            return records;
        }
    });
    return notificationCollection;
});
