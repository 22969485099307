define(function(require) {
    const alertTemplate = require('./PushAlert.template.html');
    const BaseView = require('app/Base.view');
    const $ = require('jquery');
    const _ = require('underscore');
    const CC = require('CC');
    require('./PushAlert.css');

    const pushView = BaseView.extend({
        template: _.template(alertTemplate),

        defaults: {
            alertType: 'success',
            message: 'Save Successful',
            keepShowing: false,
            animationSpeed: 200,
            timeToDisplay: BaseView.Time.THREE_SECONDS_AS_MS
        },

        events: {
            'click #dismiss-push-alert': 'dismiss',
            mouseover: 'shouldKeepShowing',
            mouseout: 'dontKeepShowing'
        },

        initialize: function(options) {
            _.extend(this, this.defaults, options);

            this.listenTo(CC.Events, CC.Events.PAGE_CLEAR_ALERT, () => {
                this.dismiss();
            });
            return this;
        },

        render: function() {
            const self = this;
            const $el = $(this.el);

            const iconLookup = {
                success: 'lotacon lotacon-ok',
                danger: 'lotacon lotacon-remove-circle'
            };

            $el.html(
                self.template({
                    intro: iconLookup[this.alertType] || iconLookup['success'],
                    alertType: this.alertType,
                    message: self.message
                })
            );

            $('#push-alert').slideDown(this.animationSpeed);
            this.setTimeout();

            return self;
        },

        onClose: function() {
            this.keepShowing = false;
            this.dismiss();
        },

        shouldKeepShowing: function() {
            this.keepShowing = true;
        },

        dontKeepShowing: function() {
            this.keepShowing = false;
            this.setTimeout();
        },

        setTimeout: function() {
            if (this.timeToDisplay > 0) {
                this.timeout = setTimeout(() => {
                    this.dismiss();
                }, this.timeToDisplay);
            }
        },

        dismiss: function(evt) {
            //if this is coming from a click, dismiss it
            if (!_.isUndefined(evt)) {
                this.keepShowing = false;
            }

            window.clearTimeout(this.timeout);
            if (!this.keepShowing) {
                $('#push-alert').slideUp(this.animationSpeed);
            }
        }
    });
    return pushView;
});
