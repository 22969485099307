define(function(require) {
    const _ = require('underscore');
    const Backbone = require('backbone');

    var FeatureLinkModel = Backbone.Model.extend({
        defaults: {
            isBeta: false,
            isUpsell: false,
            shouldUpsell: false,
            isAuthorized: false,
            upsellMessage: 'Add-on'
        },

        initialize: function(models, options) {
            var self = this;

            const isAuthorized = _.some(this.get('links'), function(link) {
                return link.isActive();
            });

            self.set('isAuthorized', isAuthorized);
        },

        isVisible: function() {
            // This is visible only if this user's client has the feature and there is at least one
            // link that is viewwable OR this is an upsell opportunity
            var visibleLinks = _.filter(this.get('links'), function(link) {
                return link.isActive();
            });
            return (this.get('isAuthorized') && !_.isEmpty(visibleLinks)) || this.isUpsell();
        },
        isUpsell: function() {
            // Upsell if we are not authorized (i.e. the owning client doesn't have this feature enabled)
            // AND shouldUpsell is enabled
            return !this.get('isAuthorized') && this.get('shouldUpsell');
        },

        isAuthorized: function() {
            return this.get('isAuthorized');
        }
    });

    return FeatureLinkModel;
});
