define([
    'underscore',
    'backbone',
    'CC',
    'jquery',
],
function(_,Backbone,CC,$) {
    var notificationCountModel = Backbone.Model.extend({
        
        url: function() {
            return CC.apiUrl + '/notifications/count/unread';
        },
        parse: function(response) {
            var parsed_response = {
                'count': response
            };
            return parsed_response;
        }
    });
    
    return notificationCountModel;
});