define(function(require) {
    const SinistromBreadcrumbTemplate = require('./SinistromBreadcrumb.template.html');
    const BaseView = require('app/Base.view');
    const _ = require('underscore');
    const CC = require('CC');

    const SinistromBreadcrumbView = BaseView.extend({
        template: _.template(SinistromBreadcrumbTemplate),

        defaults: function() {
            return {
                showIcon: true,
                pageDescription: undefined, // {@string}
                changeEntityView: undefined // {@Backbone.View}
            };
        },

        events: {
            'click .breadcrumb-info': 'handleIconClick',
            'click .breadcrumb-changer': 'handleChangeClick'
        },

        validation: [],

        initialize: function(options) {
            _.extend(this, this.defaults(), options);

            this.listenTo(CC.Events, 'Navigation:PageDescriptionUpdated', pageDescription => {
                this.updatePageDescription(pageDescription);
            });

            this.validateProperties();
        },

        render: function() {
            const self = this;
            const viewOptions = {
                pageContext: self.pageContext,
                description: self.pageDescription,
                pageDescription: self.pageDescription || '',
                showIcon: self.showIcon,
                showChanger: this.hasValue(this.changeEntityView)
            };

            self.$el.html(this.template(viewOptions));

            const ENDING_CHAR_COUNT = 15;
            const description = self.$('.breadcrumb-description__content')[0];
            if (description.offsetWidth < description.scrollWidth) {
                const text = description.textContent.trim();
                description.dataset.tail = text.slice(text.length - ENDING_CHAR_COUNT);
            }

            return self;
        },

        handleIconClick: function() {
            CC.Events.trigger('Nav:ShowInfoModal');
        },

        handleChangeClick: function() {
            this.assign('.breadcrumb-description', this.changeEntityView);
        },

        updatePageDescription(pageDescription) {
            this.pageDescription = pageDescription;
            this.render();
        }
    });

    return SinistromBreadcrumbView;
});
