(function(apiKey){
  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
    v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
    o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
    y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

  // Following is the Pendo documentation. It is commented out because it gets called explicitly
  // once we have the information available.

  // // Call this whenever information about your visitors becomes available
  // // Please use Strings, Numbers, or Bools for value types.
  // pendo.initialize({
  //   visitor: {
  //     id:              'VISITOR-UNIQUE-ID'   // Required if user is logged in
  //     // email:        // Optional
  //     // role:         // Optional

  //     // You can add any additional visitor level key-values here,
  //     // as long as it's not one of the above reserved names.
  //   },

  //   account: {
  //     // id:           'ACCOUNT-UNIQUE-ID' // Highly recommended
  //     // name:         // Optional
  //     // planLevel:    // Optional
  //     // planPrice:    // Optional
  //     // creationDate: // Optional

  //     // You can add any additional account level key-values here,
  //     // as long as it's not one of the above reserved names.
  //   }
  // });
})('c8d4314a-6881-4568-5478-9f0666c7de26');
