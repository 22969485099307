define(function(require) {
    const Backbone = require('backbone');

    let MenuLinkModel = Backbone.Model.extend({
        defaults: {
            isBeta: false,
            isVisible: true,
            isAuthorized: false,
            title: '',
            description: '',
            url: ''
        },
        isActive: function() {
            return this.get('isAuthorized') && this.get('isVisible');
        }
    });
    MenuLinkModel.Types = {
        Button: 'button',
        Link: 'link'
    };

    return MenuLinkModel;
});
