define(function(require) {
    const ErrorTemplate = require('./Error.template.html');
    const BaseView = require('app/Base.view');
    const _ = require('underscore');
    const CC = require('CC');

    const ErrorView = BaseView.extend({
        template: _.template(ErrorTemplate),

        defaults: {
            reason: 'This page has issues that prevent it from rendering',
            alertType: 'danger',
            code: 500,
            techReason: '',
            reasons: []
        },

        events: {},

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults, options);
        },

        render: function() {
            const self = this;
            const viewOptions = {
                code: self.code
            };

            self.$el.html(this.template(viewOptions));

            if (self.code === 400) {
                self.renderNoData({
                    el: '.error-400',
                    message: `Could not load ${window.location.pathname}`,
                    reasons: self.reasons,
                    showBoxes: false,
                    summaryMessage: _.template(
                        'For more assistance, please contact <a href="<%- helpUrl %>">support</a>.'
                    )({
                        helpUrl: CC.getConfigSetting('HelpUrl')
                    })
                });
            }
            self.renderAlert({
                el: '#message',
                alertType: self.alertType,
                message: self.reason,
                forceSuccessRenderToElement: true
            });

            if (!_.isUndefined(self.techReason)) {
                CC.utils.recordError('PageLoadError', self.techReason);
            }

            return self;
        }
    });

    return ErrorView;
});
