define(function(require) {
    const navMenuLinkTemplate = require('./NavMenuLink.template.html');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const MenuLinkModel = require('models/app/MenuLink.model');

    const link_view = Backbone.View.extend({
        template: _.template(navMenuLinkTemplate),

        initialize: function(options) {
            this.link = options.link || {};
        },

        render: function() {
            const self = this;

            self.$el.html(
                self.template({
                    link: self.link,
                    isButton: self.link.get('type') === MenuLinkModel.Types.Button
                })
            );
            return this;
        }
    });
    return link_view;
});
