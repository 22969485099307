define(function(require) {
    const CC = require('CC');
    const $ = require('jquery');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const ErrorView = require('app/Error.view');
    require('backboneSubroute');

    const BaseRouter = function(options) {
        Backbone.Router.apply(this, [options]);
    };

    _.extend(BaseRouter.prototype, Backbone.Router.prototype, {
        // stores current view
        currentView: '',
        // stores all main views related to this router as needed
        views: {},
        // custom method for mapping routes to views and auto-closing/audo-rendering
        add: function(route) {
            // keeping our selfs straight
            const self = this;

            let viewModule = route.module;
            const pageScheme = _.result(route, 'pageScheme');

            // This particluar route is not authorized so force render 404 page instead
            if (_.isFunction(route.isAuthorized) && !route.isAuthorized()) {
                viewModule = ErrorView;
                route.options = {
                    code: 404
                };
            }
            // setup variables
            // backbone router function
            self.route(route.path, route.name, function(routerId) {
                // Get the backbone route paremeters
                let routeParameters = null;
                if (route.routeFn) {
                    routeParameters = route.routeFn.call(self, arguments);
                }

                self.buildView(viewModule, routeParameters, pageScheme, routerId, route);
            });
        },

        buildView: function(ViewClass, routeParameters, pageScheme, routerId, route) {
            const self = this;
            const routePath = route.path;
            const viewElement = route.element;
            const viewParams = _.result(route, 'options');
            // check if current view needs to be closed first
            if (self.currentView && self.currentView !== self.views[routePath]) {
                self.views[self.currentView].close({ keep_element: true });
            }

            const viewOptions = {
                el: viewElement,
                appRouter: self
            };
            CC.pageScheme = pageScheme ? pageScheme.split(' ')[0] : 'page-scheme-veteris';
            $('.selfContainedToma').addClass(CC.pageScheme);
            $('.selfContainedToma').attr('data-route', route.name);

            const queryParams = CC.utils.queryToObject();

            _.extend(viewOptions, queryParams);

            if (viewParams) {
                _.extend(viewOptions, viewParams);
            }

            // look for any parameters registered in Flight controller
            if (typeof registered_params === 'object') {
                _.extend(viewOptions, registered_params);
            }

            // Override Flight with Backbone Router as we may not have refreshed
            if (routeParameters) {
                _.extend(viewOptions, routeParameters);
            }

            // This router has a more up to date version of the id than flight
            if (!_.isNull(routerId) && !isNaN(routerId)) {
                _.extend(viewOptions, {
                    id: routerId
                });
            }

            const googleAnalyticsTrackingId = CC.getConfigSetting('googleAnalyticsTrackingId');
            if (!_.isUndefined(googleAnalyticsTrackingId)) {
                if (typeof ga == 'object' || typeof ga == 'function') {
                    const myCuid = CC.utils.getUserSessionInfo('userId');
                    const userClientMemberOrgId = CC.utils.getUserMemberClientId();

                    // Set the user and client id for each page view
                    ga('set', 'dimension1', myCuid);
                    ga('set', 'dimension2', userClientMemberOrgId);

                    // Set the userId for GA User Views
                    ga('set', 'userId', myCuid);
                    ga('send', 'pageview');
                }
            }

            // instantiate new view and render it
            const View = ViewClass.default ? ViewClass.default : ViewClass;
            const view = new View(viewOptions);
            view.render();

            if (viewOptions.nav_modal) {
                CC.Events.trigger('Page:RequestModal', viewOptions.nav_modal);
            }

            CC.Events.on('impersonation:changed', function() {
                if (typeof view !== 'undefined') {
                    view.trigger('impersonation:changed');
                }
            });

            this.listenTo(this, 'RefreshView', function() {
                // Get any id that may have changed
                const fragment = Backbone.history.getFragment().split('_id/');
                const id = fragment.length > 1 ? fragment[1] : registered_params.id;

                // Close the current view and re-open it
                self.views[self.currentView].close();

                if (_.isFunction(route.isAuthorized) && !route.isAuthorized()) {
                    self.views[self.currentView] = new ErrorView(
                        _.extend(viewOptions, {
                            id: id,
                            code: 404
                        })
                    );
                } else {
                    const View = ViewClass.default ? ViewClass.default : ViewClass;

                    self.views[self.currentView] = new View(
                        _.extend(viewOptions, {
                            id: id
                        })
                    );
                }

                self.views[self.currentView].render();
            });

            // global event pub
            CC.Events.trigger('route_changed');

            // assign values to router parameters for future check
            self.views[routePath] = view;
            self.currentView = routePath;
        },

        start: function(rootPath) {
            const self = this;
            if (Backbone.History.started) {
                Backbone.history.loadUrl();
                return;
            }
            if (!Backbone.history.start({ root: rootPath, pushState: true })) {
                const message = `Context set to : ${rootPath} but actual pathname is ${location.pathname}`;
                CC.utils.trackEvent('GLOBAL', 'ControllerError', message);
                CC.utils.recordError('Controller Error', message);
                CC.Events.trigger('Page:showFatalError', 'Something went wrong with the url', undefined, [
                    `If white labeling, check the subdomain (eg. ${location.hostname}/{subdomain})`
                ]);
                console.error(message);
            }

            this.listenTo(CC.Events, 'Router:Navigate', function(fragment, options) {
                self.navigate(fragment, options);
            });

            self.listenTo(CC.Events, 'PageNavigation:Change', function(route, options) {
                // Cancel all outstanding ajax request on navigation change
                _.each(self.xhrs, function(xhr) {
                    xhr.abort();
                });
                self.navigate(route, options);
            });

            // Keep track of all the current xhr request
            self.xhrs = [];
            $(document).ajaxSend(function(evt, request, settings) {
                self.xhrs.push(request);
            });
        },
        stop: function() {
            Backbone.history.stop();
        }
    });

    BaseRouter.extend = Backbone.Router.extend;

    return BaseRouter;
});
