define(function(require) {
    const impersonationMenuTemplate = require('./impersonationMenu.template.html');
    const BaseView = require('app/Base.view');
    const $ = require('jquery');
    const _ = require('underscore');
    const CC = require('CC');
    require('typeAhead');

    const impersonationMenu = BaseView.extend({
        template: _.template(impersonationMenuTemplate),
        initialize: function(options) {
            const self = this;

            self.availableImpersonatables = [];

            return self;
        },

        events: {
            'click #startImpersonation': 'startImpersonation'
        },

        render: function() {
            const self = this;
            const $el = $(this.el);

            // build template data
            $el.html(self.template({}));

            self.loadAvailableImpersonatables();

            // return view
            return self;
        },

        save: function() {
            const self = this;

            const selectedUsername = $('#impersonationPicker').val();

            const data = { username: selectedUsername };
            CC.clearSession();

            return $.ajax({
                url: `${CC.apiUrl}/users/impersonateUser`,
                type: 'PUT',
                data: JSON.stringify(data),
                processData: false,
                contentType: 'application/json',
                crossDomain: true,
                xhrFields: { withCredentials: true },
                success: function() {
                    CC.utils.storeWhiteLabelKey(undefined);
                    CC.clearSession();
                    window.location.reload(); //force the browser to "refresh"
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    self.$el
                        .find('#impersonationStatus')
                        .text(`Error starting impersonation from impersonation modal: ${errorThrown}`)
                        .addClass('text-warning');
                }
            });
        },

        loadAvailableImpersonatables: function() {
            const self = this;

            $.ajax({
                type: 'GET',
                url: `${CC.apiUrl}/users/impersonatables`,
                cache: false,
                success: function(data, text_status, xhr) {
                    self.availableImpersonatables = data;
                    self.setupImpersonatableSuggestBox();
                },
                error: function(xhr, text_status, error_thrown) {}
            });
        },

        setupImpersonatableSuggestBox: function() {
            const self = this;

            self.$('#impersonatablesSpinner').hide();
            self.$('#impersonationForm').show();

            const impersonatablesSearch = self.$('#impersonationPicker');
            impersonatablesSearch
                .typeahead(
                    {
                        hint: true,
                        highlight: true,
                        minLength: 3,
                        limit: 8
                    },
                    {
                        name: 'impersonationPicker',
                        displayKey: 'value',
                        source: self.impersonatableNameMatcher()
                    }
                )
                .trigger('focus');

            self.$('#impersonationPicker').on('typeahead:selected', function(ev, suggestion) {
                self.trigger('save');
            });
        },

        impersonatableNameMatcher: function(strs) {
            const self = this;

            return function findMatches(q, cb) {
                const matches = [];
                let matchCount = 0;

                _.each(self.availableImpersonatables, function(anImpersonatable) {
                    if (matchCount < 10) {
                        const username = anImpersonatable.username;

                        if (username.toLowerCase().indexOf(q.toLowerCase()) !== -1) {
                            matches.push({ value: username });
                            matchCount++;
                        }
                    }
                });

                cb(matches);
            };
        }
    });
    return impersonationMenu;
});
