define(function(require) {
    const SinistromMenuTemplate = require('./SinistromMenu.template.html');
    const BaseView = require('app/Base.view');
    const NavMenuFeatureView = require('./NavMenuFeature.view');
    const NavMenuLinkView = require('./NavMenuLink.view');
    const ServiceMenuView = require('./ServiceMenu.view');
    const GlobalSearchView = require('./GlobalSearch.view');
    const BasicModalView = require('./modals/BasicModal.view');
    const SinistromBreadcrumbView = require('./SinistromBreadcrumb.view');

    const _ = require('underscore');
    const CC = require('CC');
    const $ = require('jquery');
    const SimpleBar = require('simplebar').default;
    require('simplebar/dist/simplebar.css');
    require('./Sinistrom.css');

    const SinistromMenuView = BaseView.extend({
        template: _.template(SinistromMenuTemplate),

        defaults: function() {
            return {
                hasFinancialViews: CC.authorizeByPermission('View Financials'),
                hasFinancialCRUD: CC.authorizeByPermission('Import/Create/Edit Financials'),
                pageContext: 'Lotame',
                pageDescription: '',
                breadcrumbViewClass: SinistromBreadcrumbView,
                breadcrumbViewOptions: undefined
            };
        },

        events: {
            'click .nav-menu-feature': 'onMenuClick',
            'click .nav-menu-feature-close': 'onMenuCloseClick',
            'click .menuNavLink': 'onMenuNavLinkClicked',
            'click .menuItem': 'onMenuItemClicked',
            'click .search-icon': 'onSearchIconClicked'
        },

        validation: [
            {
                name: 'navPermssions',
                required: true
            }
        ],

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults(), options);

            self.links = _.extend(
                {},
                self.navPermssions.Analytics.get('links'),
                self.navPermssions.Reporting.get('links'),
                self.navPermssions.Audiences.get('links'),
                self.navPermssions.Optimizer.get('links'),
                self.navPermssions.Collaborate.get('links'),
                self.navPermssions.MyData.get('links'),
                self.navPermssions.Connections.get('links')
            );

            self.validateProperties();

            self.allPossibleFeatureLinks = [];

            for (const linkKey in self.links) {
                const linkObj = self.links[linkKey];

                if (linkObj.get('isVisible')) {
                    self.allPossibleFeatureLinks.push(linkObj);
                }
            }

            self.listenTo(CC.Events, 'ShortcutKey:FocusOnGlobalSearch', () => self.onSearchIconClicked());
        },

        render: function() {
            const self = this;
            const whiteLabelKey = CC.SessionData.clientFlags.get('whiteLabelKey');

            const viewOptions = _.extend(
                {},
                {
                    navPermissions: self.navPermssions,
                    app: self.app,
                    labelClass: whiteLabelKey ? 'white-label' : 'standard-label',
                    lotameLogo: CC.getConfigSetting('lotameLogo'),
                    logo: CC.utils.getBrandLogo(whiteLabelKey),
                    fallbackLogo: CC.getConfigSetting('lotameLogo'),
                    menuScheme: self.menuScheme
                },
                self.links
            );

            self.$el.html(this.template(viewOptions));
            if (self.breadcrumbViewOptions) {
                self.renderSubView('.main-breadcrumb', self.breadcrumbViewClass, self.breadcrumbViewOptions);
            }

            self.renderSubView('#audiences', NavMenuFeatureView, {
                model: self.navPermssions.Audiences
            });

            self.renderSubView('#mydata', NavMenuFeatureView, {
                model: self.navPermssions.MyData
            });

            self.renderSubView('#collaborate', NavMenuFeatureView, {
                model: self.navPermssions.Collaborate
            });

            self.renderSubView('#optimizer', NavMenuFeatureView, {
                model: self.navPermssions.Optimizer
            });

            self.renderSubView('#analytics', NavMenuFeatureView, {
                model: self.navPermssions.Analytics
            });

            self.renderSubView('#reporting', NavMenuFeatureView, {
                model: self.navPermssions.Reporting
            });

            self.renderSubView('#connections', NavMenuFeatureView, {
                model: self.navPermssions.Connections
            });

            self.renderSubView('#serviceMenu', ServiceMenuView, {
                app: self.app,
                menuScheme: self.menuScheme
            });

            self.renderSubmenus();

            self.$(`.navbar-nav li.${self.menuName}`).addClass('selected');

            new SimpleBar(document.getElementById('mainNavbar'));

            return self;
        },

        onSearchIconClicked: function() {
            const self = this;
            CC.Events.trigger(
                'Page:showModal',
                new BasicModalView({
                    contentView: new GlobalSearchView({
                        app: self.app,
                        menuScheme: self.menuScheme,
                        navPermissions: self.navPermssions,
                        allPossibleFeatureLinks: self.allPossibleFeatureLinks
                    }),
                    modalTitle: '',
                    showSave: false,
                    showCancel: false,
                    showClose: false,
                    modalId: 'global_search',
                    isScrollable: false
                })
            );
        },

        onMenuClick: function(e) {
            const self = this;
            const requestedSubmenu = self
                .$(e.currentTarget)
                .parent()
                .data('submenu-id');

            self.$('.secondary-nav').fadeIn();
            self.$('.vertical-submenu').addClass('hidden');
            self.$(`.${requestedSubmenu}`).removeClass('hidden');
            self.$('.navbar-nav li').removeClass('active');

            self.$(e.currentTarget)
                .parent()
                .addClass('active');

            // Stop here so that the click away handler won't get called
            e.stopPropagation();

            $(document).one('click', function(evt) {
                const e = $(evt.target);
                if (!e.parents('.secondary-nav').length && !e.hasClass('secondary-nav')) {
                    self.onMenuCloseClick();
                }
            });
        },

        onMenuCloseClick: function(e) {
            this.$('.secondary-nav').fadeOut();
            this.$('.top-submenu').removeClass('active');
        },

        onMenuNavLinkClicked: function(e) {
            const linkKey = this.$(e.target)
                .closest('li')
                .attr('id');
            CC.utils.trackEvent('GlobalNav', 'NavBar - Clicked', linkKey);
        },

        onMenuItemClicked: function(e) {
            const linkKey = this.$(e.target).attr('menuitemkey');
            CC.utils.trackEvent('GlobalNav', 'NavBar - Clicked', linkKey);

            if (linkKey === 'Logout') {
                CC.utils.storeWhiteLabelKey(registered_params['subdomain']);
                CC.clearSession();
            }
        },

        renderSubmenus: function() {
            const self = this;

            _.each(self.navPermssions, function(feature) {
                const links = _.pairs(feature.get('links'));
                _.each(links, function(link) {
                    if (link[1].isActive()) {
                        self.renderSubView(`#${link[0]}`, NavMenuLinkView, {
                            link: link[1]
                        });
                    }
                });
            });

            return self;
        }
    });

    return SinistromMenuView;
});
